import React, { useState } from 'react';
import { Container } from '@mui/material';
import { VscUnmute } from "react-icons/vsc";
import { VscMute } from "react-icons/vsc";


const Videos = () => {
  const [isMuted, setIsMuted] = useState(true);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    const video = document.getElementById('video');
    video.muted = !video.muted;
  };

  return (
    <div className='video-div' id="Home">
      <Container>
        <div className='video-background'>
          <video id="video" className='video_css' autoPlay loop muted={isMuted}>
            <source src='https://seyon-di-documents.s3.ap-south-1.amazonaws.com/seyon1.mp4' type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="mute_btn" onClick={handleToggleMute}>{isMuted ? <VscMute /> : <VscUnmute />}</button>
        </div>
      </Container>
    </div>
  );
};

export default Videos;

