import React from "react";
import { TextField, Container, FormHelperText, FormLabel } from "@mui/material";
import Swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import $ from 'jquery';
function ContactForm() {
  $(document).ready(function () {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 100) {
        $('#backtotop').fadeIn();
      } else {
        $('#backtotop').fadeOut();
      }
    });
    $('#backtotop').click(function () {
      $("html, body").animate({
        scrollTop: 0
      }, 600);
      return false;
    });
  });
  const { handleSubmit, control, reset, formState: { errors } } = useForm({});
  // Email validation function using regular expression
  const validateEmail = (value) => {
    if (value !== '') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const isValid = emailRegex.test(value);
      return isValid || 'Invalid email address';
    }
  };
  const onSubmit = (data) => {
    const config = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    };
    var req = {
      Name: data.name,
      Email: data.email,
      MobileNumber: data.phoneNumber,
      Message: data.message,
    }
    return axios.post("https://formspree.io/f/mdoqzpbw", req, config).then((result) => {
      if (result.data) {
        Swal.fire({
          icon: "success",
          title: "Thank you for contacting Seyon-di.",
          text: "We will get back to you soon",
        });
        reset()
      }
    }).catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Submission Error",
        text: "Failed to submit form. Please try again later.",
      });
    });
  }
  const onError = (errors, e) => { };
  return (
    <section id="Contact" className="sys_padd">
      <div className="container col-sm-12 col-md-7" >
        <h2 className="hr-lines1" style={{ color: "darkgray" }}>
          Contact Us
        </h2>
        <Container>
          <div className="contact-form">
            <form className="signin-form" onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="form-row">
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormLabel shrink="true" className='input_Lable'>Name <span className="text-danger">*</span></FormLabel>
                    <Controller name="name" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          variant='outlined'
                          // label="Name"
                          placeholder='Enter Your Name'
                          fullWidth
                          sx={{ background: 'white' }}
                        />} />
                    <FormHelperText className='text-danger'>{errors.name && errors.name.message}</FormHelperText>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="form-group mt-2">
                    <FormLabel shrink="true" className='input_Lable'>Email <span className="text-danger">*</span></FormLabel>
                    <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          variant='outlined'
                          // label="Email"
                          placeholder='Enter Your Email'
                          fullWidth
                          sx={{ background: 'white' }}
                        />} />
                    <FormHelperText className='text-danger'>{errors.email && errors.email.message}</FormHelperText>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="form-group mt-2">
                    <FormLabel shrink="true" className='input_Lable'>Phone Number <span className="text-danger">*</span></FormLabel>
                    <Controller name="phoneNumber" control={control} defaultValue="" rules={{ required: 'Phone Number is required' }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          variant='outlined'
                          // label="Phone Number"
                          placeholder='Enter Your Phone Number'
                          fullWidth
                          sx={{ background: 'white' }}
                          type="number"
                          onWheel={(event) => event.currentTarget.querySelector('input')?.blur()}
                        />} />
                    <FormHelperText className='text-danger'>{errors.phoneNumber && errors.phoneNumber.message}</FormHelperText>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="form-group mt-2">
                    <FormLabel shrink="true" className='input_Lable'>Message <span className="text-danger">*</span></FormLabel>
                    <Controller name="message" control={control} defaultValue="" rules={{ required: 'Message is required' }}
                      render={({ field }) =>
                        <TextField
                          {...field}
                          variant='outlined'
                          // label="Message"
                          placeholder='Enter Your Message'
                          fullWidth
                          multiline
                          rows={3}
                          sx={{ background: 'white' }}
                        />} />
                    <FormHelperText className='text-danger'>{errors.message && errors.message.message}</FormHelperText>
                  </div>
                </div>
              </div>
              <div className="btn-div">
                <button
                  className="submit_btn"
                  type="submit"
                >
                  SUBMIT
                </button>

              </div>
            </form>
          </div>
          <div className="address-form">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="address-details">
                  <h5>Germany : </h5>
                  <p>Flachter Straße. 14 71287 Weissach</p>
                  <p>Ph : +49 177 2023046.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="address-details">
                  <h5>Philippines : </h5>
                  <p>B2, L1, University Executive Villa, Burol Main, Dasmarinas City,  Cavite,</p>
                  <p>Ph : +63 9665915092.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="address-details">
                  <h5>India : </h5>
                  {/* <p>43, G1, Sri Venkateshwara Flats, Venkateshnagar Main Road, Virugambakkam, Chennai, Tamilnadu, India – 600 093. </p> */}
                  {/* <p>Flat 18, 2nd floor, PA Ashtalakshmi Apartments,26/11 MG Road, Adyar, Chennai 600041</p> */}
                  <p>
                    Flat 18, 2nd floor, <br></br>
                    PA Ashtalakshmi Apartments,<br></br>
                    26/11 MG Road, <br></br>
                    Adyar, Chennai 600041.</p>
                  <p>Ph : +91 9025127830.</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <span id="backtotop" title="Back to top" style={{ display: 'none' }}>&uarr;</span>
    </section>
  );
}

export default ContactForm;